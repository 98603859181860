import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"
import Logo from "@components/common/svg/logo.svg"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Link className={props.className} to={"/2020"}>
      <Logo />
    </Link>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
> a {
  width: 112px;
  height: 100px;
}
`
const spStyle = `
> a {
  width: 92px;
  height: 82px;
  svg {
    width: 57px;
    height: 39px;
  }
}
`
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  > a {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
