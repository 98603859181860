import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// ______________________________________________________
//
export type NavListItem = {
  label: string
  href: string
  target?: "_blank"
}
type Props = {
  className?: string
} & NavListItem
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <li className={props.className}>
    {props.target && (
      <a href={props.href} target={props.target} rel="noopener noreferrer">
        {props.label}
      </a>
    )}
    {!props.target && <Link to={props.href}>{props.label}</Link>}
  </li>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  a {
    font-size: 1.4rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.light};
    transition: color ease-out 0.1s;
    &::before {
      content: "- ";
    }

    &:hover {
      color: ${({ theme }) => theme.colors.sky};
    }
  }
`
// ______________________________________________________
//
export default StyledComponent
