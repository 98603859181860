import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Nav from "./nav"
import Organizer from "./organizer"
import { NavList } from "./nav/list"
// ______________________________________________________
//
type Props = {
  className?: string
  lists: NavList[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <footer className={props.className}>
    <div className="container">
      <Nav lists={props.lists} />
      <Organizer className="organizer" />
    </div>
  </footer>
)
// ______________________________________________________
//
const pcStyle = `
> .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 970px;
  margin: 0 auto;
  padding: 40px 40px 120px;
}
`
const spStyle = `
padding: 20px;
> .container {
  > .organizer {
    padding-top: 20px;
    border-top: 1px solid rgba(255,255,255,.2);
  }
}
`
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.primary};
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
