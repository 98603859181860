import React from "react"
import styled from "styled-components"
import Button from "@components/common/button"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Button
      className="btnBuyTicket"
      label="BUY TICKET"
      href="https://ti.to/tsconfjp/2020"
      target="_blank"
      height={50}
      fontSize={16}
      backgroundColor="secondary"
    />
    <Button
      className="btnSponsorWanted"
      label="SPONSOR WANTED"
      href="/2020/entries/sponsor/"
      height={50}
      fontSize={16}
      backgroundColor="accent"
    />
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  > * {
    margin-bottom: 20px;
  }
`
// ______________________________________________________
//
export default StyledComponent
