import React from "react"
import styled from "styled-components"
// ______________________________________________________
//
type Props = {
  className?: string
  isOpen: boolean
  onClick: () => void
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div
    className={`${props.className} ${props.isOpen ? "isOpen" : ""}`}
    onClick={props.onClick}
  >
    <span />
    <span />
    <span />
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    display: block;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.light};
    transform: scaleX(1);
    transition-duration: 0.2s;
    transform-origin: right top;
    &:nth-child(1) {
      width: 34px;
      margin: 0 0 7px 0px;
    }
    &:nth-child(2) {
      width: 26px;
      margin: 0 0 7px 8px;
    }
    &:nth-child(3) {
      width: 18px;
      margin: 0 0 0 16px;
    }
  }
  &.isOpen {
    span {
      transform: scaleX(10);
    }
  }
`
// ______________________________________________________
//
export default StyledComponent
