import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "@components/layouts/index"
import Arrow from "./arrow.svg"
// ______________________________________________________
//
type Props = {
  className?: string
  label: string
  href: string
  target?: string
  height: number
  fontSize?: number
  color?: keyof typeof colors
  backgroundColor: keyof typeof colors
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  if (props.target) {
    return (
      <a
        className={props.className}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{props.label}</span>
        <div className="icon">
          <Arrow />
        </div>
      </a>
    )
  }
  return (
    <Link className={props.className} to={props.href}>
      <span>{props.label}</span>
    </Link>
  )
}
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height}px;
  color: ${({ theme, color }) => (color ? theme.colors[color] : "#000")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize / 10 : 2.4)}rem;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};
  transition: color ease-in 0.1s;
  > span {
    position: relative;
    z-index: 0;
    margin-right: 10px;
  }
  > .icon {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;
    transition: transform ease-in 0.1s;
  }
  &:before {
    content: "";
    background: ${({ theme }) => theme.colors.ts};
    background-blend-mode: multiply;
    width: 100%;
    opacity: 0;
    position: absolute;
    height: 0%;
    bottom: 0;
    z-index: 0;
    transition: height ease-out 0.1s, opacity ease-in 0.1s;
  }
  :hover {
    color: ${({ theme }) => theme.colors.light};
    &:before {
      height: 100%;
      opacity: 1;
    }
    > .icon {
      transform: translateX(6px);
      svg {
        fill: ${({ theme }) => theme.colors.light};
      }
    }
  }
`
// ______________________________________________________
//
export default StyledComponent
