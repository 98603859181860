import { useState, useCallback, useEffect } from "react"
// ______________________________________________________
//
function setLocalStorageItem(key: string, value: string | null) {
  if (typeof window === "undefined" || value === null) return
  localStorage.setItem(key, value)
}
function getLocalStorageItem(key: string, defaultValue: string) {
  if (typeof window === "undefined") return defaultValue
  return localStorage.getItem(key) || defaultValue
}
// ______________________________________________________
//
export function useToggleLanguage() {
  const [lang, updateLang] = useState<string>("ja")
  const toggleToJA = useCallback(() => {
    updateLang("ja")
    setLocalStorageItem("lang", "ja")
  }, [])
  const toggleToEN = useCallback(() => {
    updateLang("en-US")
    setLocalStorageItem("lang", "en-US")
  }, [])
  useEffect(() => {
    if (typeof window === "undefined") return
    const lang = getLocalStorageItem("lang", "ja")
    updateLang(lang)
    setLocalStorageItem("lang", lang)
  }, [])
  return {
    lang,
    toggleToJA,
    toggleToEN,
  }
}
// ______________________________________________________
//
export type UseToggleLanguage = ReturnType<typeof useToggleLanguage>
