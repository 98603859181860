import React from "react"
import styled from "styled-components"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = {
  className?: string
} & UseToggleLanguage
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <p className={props.lang === "ja" ? "current" : ""}>
      <a onClick={props.toggleToJA}>日本語</a>
    </p>
    <p className={props.lang === "en-US" ? "current" : ""}>
      <a onClick={props.toggleToEN}>English</a>
    </p>
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  text-align: center;
  a {
    display: block;
    padding: 10px 20px;
    line-height: 2;
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.dark};
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      height: 3px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.colors.primary};
      background-color: transparent;
    }
  }
  > .current {
    a {
      color: ${({ theme }) => theme.colors.primary};
      &::after {
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`
// ______________________________________________________
//
export default StyledComponent
