import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Logo from "./logo.svg"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <h2>
      <Logo />
    </h2>
    <address>&copy;2019 TypeScript JP</address>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
`
const spStyle = `
svg {
  width: 131px;
}
`
const StyledComponent = styled(Component)`
  address {
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
