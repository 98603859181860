import React from "react"
import Helmet from "react-helmet"
// ______________________________________________________
//
type Props = {
  title?: string | null | undefined
  description?: string
  ogpImage?: string
}
// ______________________________________________________
//
const applicationLdJson = (
  name: string,
  description: string,
  ogpImage: string
) => ({
  "@context": "http://schema.org",
  "@type": "Event",
  name,
  description,
  startDate: "2020-02-22T10:30",
  endDate: "2020-02-22T21:00",
  location: {
    "@type": "Place",
    name: "NAVITIME JAPAN Co., Ltd.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "3-8-38 Minami Aoyama Tokyu Bldg",
      addressLocality: "Minami Aoyama, Minato-ku",
      postalCode: "107-0062",
      addressRegion: "Tokyo",
      addressCountry: "JP",
    },
  },
  image: [ogpImage],
  offers: {
    "@type": "Offer",
    availability: "https://schema.org/InStock",
    price: "10000",
    priceCurrency: "JPY",
    url: "https://ti.to/tsconfjp/2020",
  },
})
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  const title = props.title ?? "TSConf JP 2020"
  const description =
    props.description ??
    "TSConf JP is a TypeScript conference in Japan powered by TypeScript JP. This is the first event of TSConf in Japan."
  const ogpImage = props.ogpImage ?? "https://tsconf.jp/ogpimage.png"
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogpImage} />
      <meta property="og:site_name" content="TSConf JP 2020" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@tsconfjp" />
      <meta name="twitter:creator" content="@tsconjp" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogpImage} />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:700&display=swap"
        rel="stylesheet"
      />
      <script type="application/ld+json">
        {JSON.stringify(applicationLdJson(title, description, ogpImage))}
      </script>
      />
    </Helmet>
  )
}
// ______________________________________________________
//
export default Component
