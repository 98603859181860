import React, { useState, useCallback } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import BtnHamburguer from "./btnHamburguer"
import Menu from "./menu"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type ContainerProps = {
  className?: string
} & UseToggleLanguage
type Props = {
  isOpen: boolean
  toggleIsOpen: () => void
} & ContainerProps
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <aside className={`${props.className} ${props.isOpen ? "isOpen" : ""}`}>
    <div className="bg" onClick={props.toggleIsOpen} />
    <Menu
      isOpen={props.isOpen}
      toggleIsOpen={props.toggleIsOpen}
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
    <BtnHamburguer
      className="btnHamburguer"
      isOpen={props.isOpen}
      onClick={props.toggleIsOpen}
    />
  </aside>
)
// ______________________________________________________
//
const pcStyle = `
display: none;
`
const StyledComponent = styled(Component)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition-duration: 0.3s;
  &.isOpen {
    transform: translateX(0%);
  }
  > .bg {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  > .btnHamburguer {
    position: absolute;
    top: 5px;
    left: -65px;
  }
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
const Container: React.FC<ContainerProps> = props => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])
  return (
    <StyledComponent {...props} isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
  )
}
export default Container
