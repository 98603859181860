import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import List, { NavList } from "./list"
// ______________________________________________________
//
type Props = {
  className?: string
  lists: NavList[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    {props.lists.map(list => (
      <List
        key={list.title}
        title={list.title}
        navListItems={list.navListItems}
      />
    ))}
  </div>
)
// ______________________________________________________
//
const pcStyle = `
display: flex;
> * {
  width: 200px;
}
`
const spStyle = `
> * {
  margin-bottom: 30px;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
