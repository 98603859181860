import React from "react"
import styled from "styled-components"
// ______________________________________________________
//
type Props = {
  className?: string
  isOpen: boolean
  onClick: () => void
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div
    className={`${props.className} ${props.isOpen ? "isOpen" : ""}`}
    onClick={props.onClick}
  ></div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    position: absolute;
    top: 30px;
    left: 19px;
    background-color: ${({ theme }) => theme.colors.dark};
    transform: rotate(45deg);
  }
  &::after {
    content: "";
    display: block;
    width: 2px;
    height: 24px;
    position: absolute;
    left: 30px;
    top: 19px;
    background-color: ${({ theme }) => theme.colors.dark};
    transform: rotate(45deg);
  }
  transform: scale(0) rotate(45deg);
  transition-duration: 0.2s;
  &.isOpen {
    transform: scale(1) rotate(0deg);
    transition-delay: 0.4s;
  }
`
// ______________________________________________________
//
export default StyledComponent
