import React from "react"
import styled from "styled-components"
import Item, { NavListItem } from "./item"
// ______________________________________________________
//
export type NavList = {
  title: string
  navListItems: NavListItem[]
}
type Props = {
  className?: string
} & NavList
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <nav className={props.className}>
    <h3 className="title">{props.title}</h3>
    <ul className="items">
      {props.navListItems.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </ul>
  </nav>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  > .title {
    margin-bottom: 16px;
    font-size: 1.6rem;
    font-family: "Hiragino Kaku Gothic Pro";
    font-weight: bold;
  }
  > .items {
    > * {
      margin-bottom: 9px;
    }
  }
`
// ______________________________________________________
//
export default StyledComponent
