import React from "react"
import Helmet from "react-helmet"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import media from "styled-media-query"
import reset from "styled-reset"
// ______________________________________________________
//
export const colors = {
  dark: "#061c31",
  primary: "#1f1cb4",
  ts: "#0054b7",
  deepBlue: "#00478a",
  grape: "#5617a6",
  wood: "#007272",
  secondary: "#05ffb8",
  sky: "#4cd9ff",
  indigo: "#4e38ff",
  accent: "#ffd705",
  light: "#fff",
  pale: "#c4c4c4",
}
export const theme = {
  colors,
  layout: {},
}
type AppTheme = typeof theme
declare module "styled-components" {
  export interface DefaultTheme extends AppTheme {}
}
// ______________________________________________________
//
export const GlobalStyle = createGlobalStyle`
  ${reset}
  html, body {
    font-size: 62.5%;
    background-color: ${colors.dark};
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    ${media.lessThan("medium")`
      font-size: 1.4rem;
    `}
    ${media.greaterThan("medium")`
      font-size: 1.6rem;
    `}
    color: ${colors.light};
    line-height: 1.5;
    font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }
`
// ______________________________________________________
//
type Props = {
  env: string
}
const Component: React.FC<Props> = ({ children, env }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Helmet>
      <html lang="ja" />
      {/* <meta name="robots" content={env === "production" ? "all" : "noindex"} /> */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    {children}
  </ThemeProvider>
)
// ______________________________________________________
//
export default Component
