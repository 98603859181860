import React from "react"
import styled from "styled-components"
import BtnClose from "./btnClose"
import BtnGroup from "./btnGroup"
import SelectLanguages from "./selectLanguages"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type ContainerProps = {
  className?: string
} & UseToggleLanguage
type Props = {
  isOpen: boolean
  toggleIsOpen: () => void
} & ContainerProps
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <SelectLanguages
      className="selectLanguages"
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
    <BtnGroup className="btnGroup " />
    <BtnClose
      className="btnClose"
      isOpen={props.isOpen}
      onClick={props.toggleIsOpen}
    />
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  width: 250px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.light};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .btnGroup {
    width: 200px;
    margin-top: 40px;
  }
  > .btnClose {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`
// ______________________________________________________
//
export default StyledComponent
